import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import { unref } from "vue";
import { Endpoints } from "@/store/enums/ApiEnums";
import AxiosService from "@/services/AxiosService";

@Module({ namespaced: true })
class CampaignsStore extends VuexModule {
  public Campaigns = [];
  initialCampaign = {};
  public ActiveCampaign = this.initialCampaign;

  get activeCampaign() {
    return this.ActiveCampaign;
  }

  get fetchedCampaigns() {
    return this.Campaigns;
  }

  @Mutation
  SET_CAMPAIGN(payload: any): void {
    this.ActiveCampaign = payload;
  }

  @Mutation
  SET_CAMPAIGNS(payload: any): void {
    this.Campaigns = payload;
  }

  @Mutation
  CLEAR_CAMPAIGN(): void {
    this.ActiveCampaign = this.initialCampaign;
  }

  @Action
  public clearCampaign() {
    this.context.commit("CLEAR_CAMPAIGN");
  };

  @Action
  public setCampaign(payload) {
    this.context.commit("SET_CAMPAIGN", payload);
  };

  @Action
  public fetchCampaign(id: string | number) {
    if (id) {
      AxiosService.get(
        Endpoints.ENDPOINT_CAMPAIGN + "/" + id
      ).then(async (response) => {
        const data = response.data.data;
        this.context.commit("SET_CAMPAIGN", data);
        this.context.commit("SET_CAMPAIGNS", [data]);
      });
    }
  };

  @Action
  public fetchCampaigns(query: string | string[]) {
    const q = unref(query);
    if (q.length > 2) {
      const filter = "?filter" + "[name]" + "[like]=" + q;
      AxiosService.get(Endpoints.ENDPOINT_CAMPAIGN + filter).then((response) => {
        const data = response.data.data;
        this.context.commit("SET_CAMPAIGNS", data);
      });
    }
    else {
      AxiosService.get(Endpoints.ENDPOINT_CAMPAIGN).then((response) => {
        const data = response.data.data;
        if (Object.keys(this.ActiveCampaign).length > 0) {
          data.push(unref(this.ActiveCampaign));
        }
        this.context.commit("SET_CAMPAIGNS", data);
      });
    }
  };

  @Action
  public async createCampaign(payload) {
    await AxiosService.post(Endpoints.ENDPOINT_CAMPAIGN, payload).then(
      (response) => {
        const data = response.data.data;
        this.context.commit("SET_CAMPAIGN", data);
      },
    );
  };

  @Action
  public async fetchCampaignsForMigration() {
    await AxiosService.get(Endpoints.ENDPOINT_CAMPAIGN + "?filter[and][affiliate-status][eq]=4&expand=campaignPartners&per-page=10000").then(
      (response) => {
        const data = response.data.data;
        this.context.commit("SET_CAMPAIGNS", data);
      },
    );
  };

}

export default CampaignsStore;
