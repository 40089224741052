import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import AxiosService from "@/services/AxiosService";
import { router } from "@/router";
import getLocale from "@/helpers/getLocale";
import { Endpoints } from "@/store/enums/ApiEnums";
import store from "@/store";

@Module({ namespaced: true, store })
class Enum extends VuexModule {
  public enumerators = {};

  /*
   *
   *  TODO
   *   add additional enumerators here (configurable)
   *   create action to fetch enumerators from config
   * */

  get getEnums() {
    return this.enumerators;
  }

  @Mutation
  public setEnumerators(enumerators: string): void {
    this.enumerators = enumerators;
  }

  @Mutation
  public setEnumValue(payload: { [key: string]: string }): void {
    this.enumerators = { ...this.enumerators, ...payload };
  }

  @Action
  initializeEnum(lang = "") {
    router.isReady().then(() => {
      const query = router.currentRoute.value.query; //get query from url

      const locale = lang === "" ? getLocale(query) : getLocale(lang); //helper class return locale

      //const locale = getLocale(query); //helper class return locale
      this.context.dispatch("fetchEnumerators", locale).then(() => {
        if (store.getters["auth/isLoggedIn"]) {
          this.context.dispatch("fetchAdditionalEnumerators", {
            endpoint: Endpoints.ENDPOINT_AD_CHANNELS_TYPES,
            name: "ad-channel-types",
            idKey: "id",
            valueKey: "name",
          });
        }
      });
    });
  }

  @Action({ rawError: true })
  fetchEnumerators(locale: string): Promise<any> {
    return AxiosService.get(Endpoints.ENDPOINT_COMMON_ENUM + locale).then(
      (response) => {
        this.context.commit("setEnumerators", response.data.data);
        return Promise.resolve(response);
      },
      (error) => {
        const message =
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action({ rawError: true })
  fetchAdditionalEnumerators(payload: { endpoint: string; name: string; idKey: string; valueKey: string }) {
    router.isReady().then(() => {
      return AxiosService.get(payload.endpoint + "?per-page=100000").then(
        (response) => {
          const _data = response.data.data.reduce((acc, item) => {
            acc[item[payload.idKey]] = item[payload.valueKey];
            return acc;
          }, {});

          this.context.commit("setEnumValue", { [payload.name]: _data });
          return Promise.resolve(response);
        },
        (error) => {
          const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          return Promise.reject(message);
        }
      );
    });
  }
}

export default Enum;
