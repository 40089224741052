import { RouteRecordRaw } from "vue-router";

export const Mail: Array<RouteRecordRaw> = [
  {
    path: "/mail-outbox",
    name: "mail-outbox-index",
    component: () => import("@/views/email/mail-outbox/MailOutboxManagementIndex.vue"),
    meta: { resources: ["MailOutbox"] },
  },
  {
    path: "/mail-tool",
    name: "mail-tool-index",
    component: () => import("@/views/email/mail-tool/MailToolIndex.vue"),
    meta: { resources: ["MailTool"] },
  },
];
export default Mail;
