import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import { unref } from "vue";
import { Endpoints } from "@/store/enums/ApiEnums";
import AxiosService from "@/services/AxiosService";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

import { i18n } from "@/helpers/i18nSet";
const { t } = i18n.global;

@Module({ namespaced: true })
class CouponsStore extends VuexModule {
  public Coupons = {};
  initialCoupon = {
    id: null,
    original_id: null,
    campaign_id: null,
    user_id: null,
    form: null,
    type: null,
    code: null,
    url: null,
    valid_from: null,
    valid_to: null,
    dates: [],
    discount_value: null,
    description: null,
    detailed_description: null,
    limited_count: null,
    used_count: null,
    status: null,
    created_at: null,
    updated_at: null,
  };

  public ActiveCoupon = {};

  get activeCoupon() {
    return this.ActiveCoupon;
  }

  @Mutation
  SET_COUPON(payload: any): void {
    Object.defineProperty(payload, 'dates', {
      value: [],
      writable: true,
    });
    payload.dates[0] = payload.valid_from;
    payload.dates[1] = payload.valid_to;
    this.ActiveCoupon = payload;
  }

  @Mutation
  CLEAR_COUPON(): void {
    this.ActiveCoupon = { ...this.initialCoupon };
  }

  @Action
  public clearCoupon() {
    this.context.commit("CLEAR_COUPON");
  };

  @Action
  public fetchCoupon(id) {
    AxiosService.get(
      Endpoints.ENDPOINT_COUPON + "/" + id
    ).then((response) => {
      const coupon = response.data.data;
      this.context.commit("SET_COUPON", coupon);
    });
  };

  @Action
  public createCoupon(coupon: any) {
    const {
      campaign_id,
      user_id,
      form,
      type,
      code,
      url,
      valid_from,
      valid_to,
      discount_value,
      description,
      detailed_description,
      limited_count,
      used_count,
      status } = unref(coupon);

    AxiosService.post(Endpoints.ENDPOINT_COUPON, {
      campaign_id,
      user_id,
      form:Number(form),
      type,
      code,
      url,
      valid_from,
      valid_to,
      discount_value,
      description,
      detailed_description,
      limited_count: Number(limited_count),
      used_count: Number(used_count),
      status
    }).then(
      () => {
        store.dispatch(
          Actions.NOTIFY_ADD_NOTIFICATION,
          {
            type: "success",
            body: { type: 422, message: t("crud.create-success") },
          },
          {
            root: true,
          }
        );
      },
      (error) => {
        store.dispatch(
          Actions.NOTIFY_ADD_NOTIFICATION,
          {
            type: "error",
            body: error,
          },
          {
            root: true,
          }
        );
      }
    );
  };

  @Action
  public updateCoupon(coupon: any) {
    const {
      code,
      campaign_id,
      id,
      user_id,
      form,
      type,
      url,
      valid_from,
      valid_to,
      discount_value,
      description,
      detailed_description,
      limited_count,
      used_count,
      status } = unref(coupon);

    AxiosService.put(Endpoints.ENDPOINT_COUPON + "/" + id, {
      user_id,
      code,
      campaign_id,
      form:Number(form),
      type,
      url,
      valid_from,
      valid_to,
      discount_value,
      description,
      detailed_description,
      limited_count: Number(limited_count),
      used_count: Number(used_count),
      status
    }).then(
      () => {
        store.dispatch(
          Actions.NOTIFY_ADD_NOTIFICATION,
          {
            type: "success",
            body: { type: 422, message: t("crud.update-success") },
          },
          {
            root: true,
          }
        );
      },
      (error) => {
        store.dispatch(
          Actions.NOTIFY_ADD_NOTIFICATION,
          {
            type: "error",
            body: error,
          },
          {
            root: true,
          }
        );
      }
    );
  };

}

export default CouponsStore;
