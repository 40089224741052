<template>
  <notifications-list />
  <el-config-provider :locale="elementPlusLocale">
    <router-view />
  </el-config-provider>
</template>

<script lang="ts">
  import { Options, Vue } from "vue-class-component";
  import { Action, namespace } from "s-vuex-class";
  import NotificationsList from "@/components/notifications/NotificationsList.vue";
  import { initializeComponents } from "@/core/plugins/keenthemes";
  //import { Actions, Mutations } from "@/store/enums/StoreEnums";
  import store from "@/store";
  import { Watch } from "vue-property-decorator";
  import AxiosService from "@/services/AxiosService";
  import { Endpoints } from "@/store/enums/ApiEnums";

  //import { ElConfigProvider } from "element-plus";

  const Auth = namespace("auth");
  const Enum = namespace("enums");
  const i18n = namespace("i18n");

  @Options({
    components: {
      NotificationsList,
    },
  })
  export default class App extends Vue {
    private theme = "light";
    private elementPlusLocale = "";

    @Auth.State("user")
    private currentUser!: any;

    @Auth.Action
    private signOut!: () => void;

    @Enum.Action
    private initializeEnum!: () => void;

    @i18n.Action
    private initializeI18n!: () => void;

    @Action
    private setLoading!: any;

    @Watch("$store.state.i18n.locale")
    onChangeLocale(): any {
      this.elementPlusLocale = store.state.i18n.elementPlusMessages; //elementPlus - change global locale
    }

    //check token when the tab gains focus
    checkToken = async () => {
      if (store.state.auth.user.token) {
        AxiosService.get(Endpoints.ENDPOINT_MY_PROFILE);
      }
    };

    handleFocus = () => {
      this.checkToken(); // Check token when the tab gains focus
    };

    created() {
      this.setLoading(true);

      const loadMessagesAndEnums = async () => {
        try {
          // Wait for both initializeI18n and initializeEnum to complete
          await Promise.all([this.initializeI18n(), this.initializeEnum()]);
          // Once both are done, assign the i18n messages
          this.elementPlusLocale = store.state.i18n.elementPlusMessages;
        } catch (error) {
          console.error("Error initializing i18n or enums", error);
          this.setLoading(false); // Hide loading spinner even in case of an error
        }
      };

      loadMessagesAndEnums();

      /*this.theme = this.currentUser.user.theme;
      store.dispatch(Actions.ADD_BODY_CLASSNAME, this.theme + "-mode");  //dark-mode */
    }

    mounted() {
      window.addEventListener("focus", this.handleFocus);
      //store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      this.$nextTick(() => {
        initializeComponents();
      });
    }

    destroyed() {
      window.removeEventListener("focus", this.handleFocus);
    }
  }
</script>

<style lang="scss">
  @import "~animate.css";
  @import "~bootstrap-icons/font/bootstrap-icons.css";
  @import "~element-plus/dist/index.css";
  @import "~prism-themes/themes/prism-shades-of-purple.css";

  // Main demo style scss
  @import "src/assets/sass/plugins";
  @import "src/assets/sass/style";

  // Dark mode demo style scss
  //@import "assets/sass/plugins.dark";
  //@import "assets/sass/style.dark";
</style>
