import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import { unref } from "vue";
import { Endpoints } from "@/store/enums/ApiEnums";
import AxiosService from "@/services/AxiosService";


@Module({ namespaced: true })
class AdChannelsStore extends VuexModule {
  public AdChannels = {};
  initialAdChannel = {};
  public ActiveAdChannel = this.initialAdChannel;
  public AdChannelTypes = []
  public AdChannelGroups = []

  get activeAdChannel() {
    return this.ActiveAdChannel;
  }

  get fetchedAdChannels() {
    return this.AdChannels;
  }

  get adChannelsTypes() {
    return this.AdChannelTypes;
  }

  get adChannelsGroups() {
    return this.AdChannelGroups;
  }

  @Mutation
  SET_ADCHANNEL(payload: any): void {

    this.ActiveAdChannel = payload;
  }

  @Mutation
  SET_ADCHANNELS(payload: any): void {
    this.AdChannels = payload;
  }

  @Mutation
  CLEAR_ADCHANNEL(): void {
    this.ActiveAdChannel = this.initialAdChannel;
  }

  @Mutation
  SET_ADCHANNEL_TYPES(payload: any): void {
    this.AdChannelTypes = payload;
  }

  @Mutation
  SET_ADCHANNEL_GROUPS(payload: any): void {
    this.AdChannelGroups = payload;
  }

  @Action
  public clearAdChannel() {
    this.context.commit("CLEAR_ADCHANNEL");
  };

  @Action
  public async fetchAdChannel(id: string | number) {
    if (id) {
      await AxiosService.get(
        Endpoints.ENDPOINT_AD_CHANNELS + "/" + id
      ).then(async (response) => {
        const data = response.data.data;
        this.context.commit("SET_ADCHANNEL", data);
        this.context.commit("SET_ADCHANNELS", [data]);
      });
    }
  };

  @Action
  public fetchAdChannels(query: string | string[]) {
    const q = unref(query);
    if (q.length > 2) {
      const filter = "?filter" + "[name]" + "[like]=" + q;
      AxiosService.get(Endpoints.ENDPOINT_AD_CHANNELS + filter).then((response) => {
        const data = response.data.data;
        this.context.commit("SET_ADCHANNELS", data);
      });
    }
    else {
      AxiosService.get(Endpoints.ENDPOINT_AD_CHANNELS).then((response) => {
        const data = response.data.data;
        if (Object.keys(this.ActiveAdChannel).length > 0) {
          data.push(unref(this.ActiveAdChannel));
        }
        this.context.commit("SET_ADCHANNELS", data);
      });
    }
  };

  @Action
  public async fetchAdChannelsByUserId(id: string | number) {
    await
      AxiosService.get(Endpoints.ENDPOINT_AD_CHANNELS +
        "?filter[and][user_id][eq]=" + id).then((response) => {
          const data = response.data.data;
          this.context.commit("SET_ADCHANNELS", data);
        });
  };

  @Action
  public async fetchAproovedAdChannelsByUserId(id: string | number) {
    await
      AxiosService.get(Endpoints.ENDPOINT_AD_CHANNELS +
        "?filter[and][user_id][eq]=" + id + "&per-page=10000&filter[and][status][eq]=1").then((response) => {
          const data = response.data.data;
          this.context.commit("SET_ADCHANNELS", data);
        });
  };

  @Action
  public async fetchAdChannelTypes() {
    await AxiosService.get(Endpoints.ENDPOINT_AD_CHANNELS_TYPES).then((response) => {
      const data = response?.data.data;
      this.context.commit("SET_ADCHANNEL_TYPES", data);
    });
  };

  @Action
  public async fetchAdChannelGroups() {
    await AxiosService.get(Endpoints.ENDPOINT_AD_CHANNELS_TYPES_GROUP).then((response) => {
      const data = response?.data.data;
      this.context.commit("SET_ADCHANNEL_GROUPS", data);
    });
  };

}

export default AdChannelsStore;
